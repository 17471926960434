import * as React from "react"
import Link from "@components/Link"
import { motion, useAnimate, stagger } from "framer-motion"

const Icon = () => {
  const iconStyles = "h-4 w-4 pt-0.5 lg:h-5 lg:w-5 text-black dark:text-white"
  return (
    <svg
      className={`${iconStyles}`}
      // aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 14"
    >
      <path d="M11 0H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm8.585 1.189a.994.994 0 0 0-.9-.138l-2.965.983a1 1 0 0 0-.685.949v8a1 1 0 0 0 .675.946l2.965 1.02a1.013 1.013 0 0 0 1.032-.242A1 1 0 0 0 20 12V2a1 1 0 0 0-.415-.811Z" />
    </svg>
  )
}

function useButtonAnimation(isOpen: boolean) {
  const [scope, animate] = useAnimate()

  React.useEffect(() => {
    const buttonAnimations = isOpen
      ? [
          [
            ".container-button",
            { transform: "scale(1)" },
            {
              ease: [0.08, 0.65, 0.53, 0.96],
              type: "spring",
              stiffness: 260,
              damping: 20,
              duration: 0.5,
            },
          ],
          [
            ".container-button .wrapper-icon",
            { opacity: 1, transform: "scale(1)" },
            {
              at: 0.2,
              ease: [0.08, 0.65, 0.53, 0.96],
              type: "spring",
              stiffness: 260,
              damping: 20,
              duration: 0.4,
            },
          ],
          [
            ".container-button",
            { width: "18rem" },
            {
              ease: [0.08, 0.65, 0.53, 0.96],
              type: "spring",
              stiffness: 260,
              damping: 20,
              duration: 0.5,
              at: 0.6,
            },
          ],
          [
            ".container-button .wrapper-text",
            { opacity: 1 },
            {
              at: 1,
              duration: 0.3,
              transitionStart: { display: "block" },
              // transitionEnd: { display: "hidden" },
            },
          ],

          // [
          //   ".container-button",
          //   { transform: "translateX(0%)" },
          //   { ease: [0.08, 0.65, 0.53, 0.96], duration: 0.6 },
          // ],
          // [
          //   "li",
          //   { transform: "scale(1)", opacity: 1, filter: "blur(0px)" },
          //   { delay: stagger(0.05), at: "-0.1" },
          // ],
        ]
      : [
          // [
          //   ".container-button",
          //   { transform: "scale(0.5)", opacity: 0, filter: "blur(10px)" },
          //   { delay: stagger(0.05, { from: "last" }), at: "<" },
          // ],
          [
            ".container-button .wrapper-text",
            { opacity: 0 },
            ,
            {
              duration: 0.2,
              // transitionStart: { display: "block" },
              transitionEnd: { display: "hidden" },
            },
          ],
          [
            ".container-button",
            { width: "3.5rem" },
            {
              ease: [0.08, 0.65, 0.53, 0.96],
              duration: 0.2,
              at: 0.2,
            },
          ],
          [
            ".container-button .wrapper-icon",
            { opacity: 0, transform: "scale(0)" },
            {
              duration: 0.2,
              at: 0.9,
            },
          ],
          [
            ".container-button",
            { transform: "scale(0)" },
            {
              duration: 0.2,
              at: 1,
            },
          ],
        ]

    animate([
      // [
      //   "path.top",
      //   { d: isOpen ? "M 3 16.5 L 17 2.5" : "M 2 2.5 L 20 2.5" },
      //   { at: "<" },
      // ],
      // ["path.middle", { opacity: isOpen ? 0 : 1 }, { at: "<" }],
      // [
      //   "path.bottom",
      //   { d: isOpen ? "M 3 2.5 L 17 16.346" : "M 2 16.346 L 20 16.346" },
      //   { at: "<" },
      // ],
      ...buttonAnimations,
    ])
  }, [isOpen])

  return scope
}

const ButtonChat = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  const scope = useButtonAnimation(isOpen)

  // setInterval(() => {
  //   setIsOpen(!isOpen)
  // }, 2500)

  setTimeout(() => {
    setIsOpen(true)
  }, 2500)

  // https://www.apple.com/iphone-15-pro/
  const containerStyles =
    "rounded-full h-14 w-14 bg-yellow flex justify-between items-center"
  // "rounded-full px-8 pb-4 pt-3 bg-yellow hover:bg-yellow-800 focus:outline-none focus:ring-4 focus:ring-black/50"
  const linkStyles =
    "rounded-full text-base text-black transition-colors duration-300 bg-yellow hover:bg-yellow-800 focus:outline-none focus:ring-4 focus:ring-black/50 lg:text-xl inline-flex items-center justify-center whitespace-nowrap"

  return (
    <div className="fixed bottom-3 left-1/2 z-50 -translate-x-1/2 -translate-y-1 md:-translate-y-4">
      <div ref={scope}>
        <Link
          className="group"
          to={{
            cached_url: "kalender/",
            linktype: "story",
            fieldtype: "multilink",
            url: "",
          }}
        >
          <motion.div className={`${containerStyles} container-button`}>
            <motion.div
              className="wrapper-icon grow-1 shrink-1 mx-5 mb-0.5 flex flex-grow items-center  justify-center"
              initial={{
                opacity: 0,
              }}
            >
              <div className="transition-transform group-hover:translate-x-1">
                <Icon />
              </div>
            </motion.div>
            <div className="shrink-1 flex-grow-0 grow-0">
              <motion.div
                className="wrapper-text mb-0.5 mr-6"
                initial={{
                  opacity: 0,
                }}
              >
                Buche ein Video-Meeting
              </motion.div>
            </div>
          </motion.div>
        </Link>
      </div>
      {/*
      <motion.div
        className={containerStyles}
        // variants={containerVariants}
        sequence={[0.1, 0.2, 0.3, 0.4, 0.5]}
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
          duration: 0.5,
          delay: 1,
        }}
      >
        <motion.div
          className="whitespace-nowrap"
          // variants={buttonContentStates}
          initial="hidden"
          animate="open"
          transition={{
            delay: 2,
          }}
        >
          <div>
            <Icon />
          </div>
          <motion.div
            className="whitespace-nowrap"
            // variants={buttonContentStates}
            initial="hidden"
            animate="open"
            transition={{
              delay: 3,
            }}
          >
            Buche ein Video-Meeting
          </motion.div>
        </motion.div>
        <Link
          to={{
            cached_url: "kalender/",
            linktype: "story",
            fieldtype: "multilink",
            url: "",
          }}
          className={linkStyles}
        >&nbsp;
          // <div className="mr-4">
          //   <Icon />
          // </div> 
          <div className="hidden">Buche ein Video-Meeting</div>
        </Link>
      </motion.div> */}
    </div>
  )
}

export default ButtonChat
