import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
import rewriteSlug from "@utils/rewriteSlug"

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({
  children,
  to,
  activeClassName = "",
  partiallyActive = false,
  ...other
}) => {
  const isExternal = to?.url !== undefined && to?.url !== ""
  const isEmail = to?.email !== undefined

  if (isExternal) {
    return (
      <a
        href={to.url}
        {...other}
        target={to.target}
        rel={to.target === "_blank" ? "noopener noreferrer nofollow" : ""}
      >
        {children}
      </a>
    )
  } else if (isEmail) {
    return (
      <a href={`mailto:${to.email}`} {...other}>
        {children}
      </a>
    )
  } else {
    return (
      <GatsbyLink
        to={rewriteSlug(to.cached_url)}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
}

export default Link
