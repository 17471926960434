const rewriteSlug = slug => {
  // const defaultLanguage = "de/"
  let newSlug = slug
  // replaces /de/home with /de
  newSlug = newSlug.replace("home", "")
  newSlug = `/${newSlug}/`
  // remove double slashes
  newSlug = newSlug.replace(/\/\//g, "/")
  // replaces /en/blog/first-post with /blog/first-post
  // newSlug = newSlug.replace(defaultLanguage, "")
  return newSlug
}

export default rewriteSlug
