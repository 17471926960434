import * as React from "react"
// import Layout from "./src/components/layout"
import ButtonChat from "./src/components/ButtonChat"

import "./src/styles/global.css"
import "./src/styles/cookiehub.css"

export function onRouteUpdate(_, pluginOptions) {
  if (process.env.NODE_ENV === `production`) {
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
      var dataLayer = window.dataLayer || []
      dataLayer.push({ event: `gatsby-route-change` })
    }, 50)
  }
}

export const wrapPageElement = ({ element, props }) => {
  // from prop remove pageResources, pageContext, serverData
  const { pageResources, pageContext, serverData, ...rest } = props

  return (
    <div {...rest}>
      {element}
      <ButtonChat />
    </div>
  )
}
